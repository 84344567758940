<template>
    <v-app>
        <v-layout>
        <v-card class="w-100 h-100" style="border-radius: 0">
            <!-- Main Navigation /////////////////////////////////////////////////////////////////////////////////// -->
            <v-navigation-drawer v-model="drawer" temporary>
                <div class="d-flex justify-space-between align-center pa-2">
                    <div @click="triggerInertiaLink('user.profile.edit')"
                                  title="Eigenes Profil bearbeiten"
                                  class="cursor-pointer d-flex no-decoration text-decoration-none">
                        <v-avatar :color="$page.props.auth.user.avatar_path ? '' : getColorFor($page.props.auth.user.fullName)" class="ml-0 mr-2" size="small">
                            <v-img v-if="$page.props.auth.user.avatar_path" :src="$page.props.auth.user.avatar_path" />
                            <span v-else class="text-white">{{ getInitials($page.props.auth.user.fullName) }}</span>
                        </v-avatar>
                        <span class="align-self-center">{{ $page.props.auth.user.fullName }}</span>
                    </div>

                    <inertia-link title="Ausloggen" :href="$route('logout')" method="post" as="button">
                        <v-icon size="small">mdi-logout</v-icon>
                    </inertia-link>
                </div>

                <v-divider />

                <v-list nav density="compact">
                    <v-list-item @click.prevent="triggerInertiaLink('dashboard')"
                                 :href="$route('dashboard')">
                        <v-list-item-title>Dashboard</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click.prevent="triggerInertiaLink('accounts.index')"
                                 :href="$route('accounts.index')">
                        <v-list-item-title>Accounts</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click.prevent="triggerInertiaLink('campaigns.index')"
                                 :href="$route('campaigns.index')">
                        <v-list-item-title>Kampagnen</v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="$page.props.auth.user.operator"
                                 @click.prevent="triggerInertiaLink('applications.index')"
                                 :href="$route('applications.index')">
                        <v-list-item-title>Applications</v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="$page.props.auth.user.operator"
                                 @click.prevent="triggerInertiaLink('component-presets.index')"
                                 :href="$route('component-presets.index')">
                        <v-list-item-title>Landingpage Komponenten</v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="$page.props.auth.user.operator"
                                 @click.prevent="triggerInertiaLink('categories.index')"
                                 :href="$route('categories.index')">
                        <v-list-item-title>Categories</v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="$page.props.auth.user.operator"
                                 @click.prevent="triggerInertiaLink('seasons.index')"
                                 :href="$route('seasons.index')">
                        <v-list-item-title>Seasons</v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="$page.props.auth.user.operator"
                                 @click.prevent="triggerInertiaLink('users.index')"
                                 :href="$route('users.index')">
                        <v-list-item-title>Users</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click.prevent="triggerInertiaLink('catalogue')"
                                 :href="$route('catalogue')">
                        <v-list-item-title>App Katalog</v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="$page.props.auth.user.operator" href="/docs" target="_blank">
                        <v-list-item-title>Dokumentation</v-list-item-title>
                    </v-list-item>
                </v-list>

                <template v-if="$page.props.auth.user.operator">
                    <v-divider></v-divider>
                    <div class="pa-4">
                        <strong>Aktuell Online:</strong>
                        <ul class="ml-4">
                            <li v-for="(user, index) in present_users" :key="index">
                                <inertia-link :href="$route('users.edit', user)" :title="user.email" class="no-decoration">
                                    {{ user.fullName }}
                                </inertia-link>
                                <v-icon v-if="user.operator" size="small" class="ml-2" title="Operator">mdi-shield-crown-outline</v-icon>
                            </li>
                        </ul>
                    </div>
                </template>
            </v-navigation-drawer>

            <!-- System Bar //////////////////////////////////////////////////////////////////////////////////////////// -->
            <v-system-bar app class="bg-grey-darken-4" dark>
                <span class="d-none d-sm-inline">REDCODE.DE Campaigns</span> <span class="d-none d-sm-inline">&nbsp;| {{ $page.props.auth.user.fullName }}</span>
                <v-spacer />
<!--                <span>Status: {{ connectionStatus }}</span>-->
<!--                <span v-if="connectionStatus !== 'connected'" @click="channelConnect">Reconnect</span>-->
            </v-system-bar>

            <!-- App Bar / Header ////////////////////////////////////////////////////////////////////////////////////// -->
            <v-toolbar class="mt-4 bg-grey-darken-3" prominent elevation="0">
                <v-app-bar-nav-icon @click.stop="drawer = !drawer" title="Hauptmenü" />
                <v-toolbar-title>{{ $page.props.page_title }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <div v-if="currentPage_presentUsers.present_users" class="avatars-group stacked mr-6">
                    <profile-avatar v-for="currPageUser in currentPage_presentUsers.present_users"
                                    :user="currPageUser" />
                </div>
                <template v-if="showTabs" v-slot:extension>
                    <v-tabs class="w-100" :model-value="current_route">
                        <v-tab value="campaigns.show" @click="triggerInertiaVisit('campaigns.show', {campaign: $page.props.campaign.id})">Startseite</v-tab>
                        <v-tab value="campaigns.schedule" @click="triggerInertiaVisit('campaigns.schedule', {campaign: $page.props.campaign.id})">Türchen</v-tab>
                        <v-tab value="campaigns.publish" @click="triggerInertiaVisit('campaigns.publish', {campaign: $page.props.campaign.id})">Kampagne Teilen</v-tab>
                        <v-tab v-if="attrs.can.applications.create" value="campaigns.campaignSettings" @click="triggerInertiaVisit('campaigns.campaignSettings', {campaign: $page.props.campaign.id})">Einstellungen</v-tab>
                        <v-tab v-if="attrs.can.applications.create" value="campaigns.operatorSettings" @click="triggerInertiaVisit('campaigns.operatorSettings', {campaign: $page.props.campaign.id})">Operator Panel</v-tab>
                    </v-tabs>
                </template>
            </v-toolbar>

            <v-alert v-model="showSuccessMessage" type="success" closable
                     ref="success_alert"
                     style="position:absolute;z-index:10000;top:2em;right:2em;">
                {{  success_message }}
            </v-alert>
            <v-alert v-model="showErrorMessage" type="error" closable
                     ref="error_alert"
                     style="position:absolute;z-index:10000;top:2em;right:2em;">
                {{  error_message }}
            </v-alert>

            <!-- MAIN ////////////////////////////////////////////////////////////////////////////////////////////// -->
            <v-main>
                <slot></slot>
            </v-main>
        </v-card>
        </v-layout>
        <v-footer class="d-block mt-16 py-8 bg-grey-darken-3">
            <div class="w-100">
                <h4 class="text-center">SUPPORT</h4>
            </div>
            <div class="w-100 text-center">
                <a class="mx-1 text-white" href="mailto:service@redcode.org?subject=REDCDODE.DE-Specials-Support" target="_blank">E-Mail</a>
                <a class="mx-1 text-white" href="tel:+494040188751" target="_blank">Telefon</a>
                <a class="mx-1 text-white" href="https://wa.me/494040188751?text=Adventskalender-Support" target="_blank">WhatsApp</a>
                <a class="mx-1 text-white" href="https://redcodede.slack.com/archives/C01D6N1LQ59" target="_blank">Slack (öffentlich)</a>
                <a class="mx-1 text-white" href="https://www.redcode.de/#contact" target="_blank">Online-Formular</a>
            </div>

            <div class="w-100 text-center my-4">
                <h4>HAPPYMO.RE ★ ADMIN PANEL 2.0</h4>
                <p>Powered by <a class="text-white" href="https://www.redcode.de/" target="_blank">REDCODE.DE</a></p>
            </div>

            <div class="w-100 text-center">
                <inertia-link class="mx-1 text-white" :href="$route('impressum')" target="_blank" rel="nofollow noindex">IMPRESSUM</inertia-link>
                <inertia-link class="mx-1 text-white" :href="$route('datenschutz')" target="_blank" rel="nofollow noindex">DATENSCHUTZ</inertia-link>
            </div>
        </v-footer>
        <v-dialog v-model="show_global_message_dialog" width="100%" max-width="480" persistent>
            <v-card>
                <v-card-title v-html="global_message.title">
                </v-card-title>
                <v-card-text v-html="global_message.message">
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="show_global_message_dialog = false">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script setup>
import {ref, computed, onMounted, onUnmounted, watch} from "vue";
import {getInitials, getColorFor} from "../utils/helpers";
import {usePage} from "@inertiajs/inertia-vue3";
import {triggerInertiaVisit} from "../utils/inertiaHelpers";
import ProfileAvatar from "../components/ProfileAvatar";
import {useGlobalPresenceChannel} from "../utils/globalPresenceChannel";
import {usePresenceStore} from "../Stores/PresenceStore";
import echoInstance from "../services/echo";

const drawer = ref(false);
const attrs = usePage().props.value;
const tab = ref(null);
const currentPage_presentUsers = usePresenceStore();

// ----------------------------------------------------------------------------
// Global Presence Channel
// ----------------------------------------------------------------------------
const {present_users} = useGlobalPresenceChannel();

// ----------------------------------------------------------------------------
// Tabs
// ----------------------------------------------------------------------------
const current_route = computed(() => {
    return usePage().props.value.current_route;
});

const showTabs = computed(() => {
    if (['campaigns.show', 'campaigns.schedule', 'campaigns.publish', 'campaigns.operatorSettings', 'campaigns.campaignSettings'].includes(current_route.value)) {
        return true;
    }
    return false;
});

// ----------------------------------------------------------------------------
// Page Title
// ----------------------------------------------------------------------------
const page_title = computed(() => {
    return usePage().props.value.page_title;
});
watch(page_title, () => {
    document.title = page_title.value + ' | REDCODE.DE CAMPAIGNS';
});

// ----------------------------------------------------------------------------
// Session Flash Messages
// ----------------------------------------------------------------------------
const showSuccessMessage = ref(false);
const showErrorMessage = ref(false);
const success_message = computed(() => {
    showSuccessMessage.value = !!usePage().props.value.flash.success;
    return usePage().props.value.flash.success;
});
const error_message = computed(() => {
    showErrorMessage.value = !!usePage().props.value.flash.error;
    return usePage().props.value.flash.error;
});

watch(success_message, () => {
    window.setTimeout(() => {
        showSuccessMessage.value = false;
        showErrorMessage.value = false;
    }, 1500);
});

// ----------------------------------------------------------------------------
// Helper Functions
// ----------------------------------------------------------------------------
function triggerInertiaLink(routeName) {
    drawer.value = false;
    triggerInertiaVisit(routeName);
}


const global_message = ref(null);
const show_global_message_dialog = ref(false);
function onGlobalMessageReceived(event) {
    console.log("onGlobalMessageReceived", event);
    global_message.value = event;
    show_global_message_dialog.value = true;
}

// ----------------------------------------------------------------------------
// on Mounted Hook
// ----------------------------------------------------------------------------
onMounted(() => {
    document.title = usePage().props.value.page_title + ' | REDCODE.DE CAMPAIGNS';
    echoInstance.join('global.presence').listen('GlobalMessageSend', onGlobalMessageReceived);
});
onUnmounted(() => {
    echoInstance.join('global.presence')
        .stopListening('GlobalMessageSend', onGlobalMessageReceived);
    echoInstance.leave('global.presence');
});
</script>

<style lang="scss">
@import "../../css/app.scss";

.v-tab {
    border-radius: .5rem !important;
    background-color: #424242;
}
.v-slide-group-item--active {
    background-color: white;
    color: #424242;
    position: relative;

    &::before {
        content:"";
        display: block;
        width: calc(100% + 1rem);
        height: 1rem;
        background: white;
        position: absolute;
        bottom: 0;
        left: -.5rem;
        z-index: -1;
    }
    &::after {
        content:"";
        display: block;
        width: 1rem !important;
        height: 1rem !important;
        background-color: #424242;
        border-radius: 0 0 0 .5rem !important;
        position: absolute;
        bottom: 0 !important;
        right: -1rem !important;
        top: auto !important;
        left: auto !important;
        z-index: 1;
        opacity: 1 !important;
        border: none !important;
    }
}
.v-slide-group__content {
    position: relative;

    &::before {
        content:"";
        display: block;
        width: 1rem;
        height: 1rem;
        background: white;
        position: absolute;
        bottom: 0;
        right: -1rem;
        z-index: 1;
    }
}
.v-tab__slider {
    display: none;
}
</style>
