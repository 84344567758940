<template>
<v-card class="d-inline-block mr-4"
        style="width: 100%; max-width: 275px;"
        v-bind="props.component_config"
        @dragover.prevent="handleDragOver"
        @drop.prevent="handleFileDrop">
    <v-toolbar v-if="repeatableChild">
        <v-icon  class="drag-handle" title="Verschieben" size="x-large">mdi-drag-vertical</v-icon>
        <v-card-title>{{ props.label }}</v-card-title>
    </v-toolbar>
    <template v-else>
        <v-card-title>{{ props.label }}</v-card-title>
        <v-card-subtitle v-if="props.hint">{{ props.hint }}</v-card-subtitle>
    </template>

    <v-img :src="imageUrl"
           :lazy-src="`${base_url}/resources/redcode/img/favicon/apple-touch-icon.png`"
           @click="handleImageClick"
           v-bind="props.vuetify_config"
           cover :aspect-ratio="1"
           class="white--text align-end"
           style="width: 100%; max-width: 275px; max-height: 180px;">
        <div v-if="is_uploading" class="d-flex align-center justify-center fill-height">
            <!-- Progress Throbber while the asset is uploading -->
            <v-progress-circular :rotate="0"
                                 :size="50"
                                 :width="4"
                                 :model-value="upload_progress"
                                 color="primary">
                {{ upload_progress || 0 }}%
            </v-progress-circular>
        </div>
    </v-img>
    <v-card-actions>
        <input type="file" ref="uploader" style="display: none" @change="handleFileSelect">
        <v-icon @click="toggleFileSelect"
                :title="`Maximale Uploadgröße: ${humanFileSize(max_upload_size, true)}`">
            mdi-tray-arrow-up
        </v-icon>
        <v-spacer></v-spacer>
        <v-menu>
            <template v-slot:activator="{ props }">
                <v-btn icon="mdi-dots-vertical" size="x-small" variant="flat" class="ml-auto" v-bind="props">
                </v-btn>
            </template>
            <v-list density="compact">
                <v-list-item @click="handleShowCurrent" :disabled="value === ''">Vorschau</v-list-item>
                <v-list-item v-if="hasOriginal" @click="handleShowOriginal" :disabled="value === ''">Original ansehen</v-list-item>
                <v-list-item @click="handleDelete" :disabled="!isDeletable">
                    <v-list-item-title title="Es wird das Original wiederhergestellt">Löschen</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-card-actions>
    <!-- File Size Upload Warnings. @Todo: Needs restyling? -->
    <ul v-if="warnings.length">
        <li v-for="(warning, index) in warnings" :key="index">
            {{ warning }} <v-btn @click="warnings.splice(index,1)" icon="mdi-close"></v-btn>
        </li>
    </ul>
</v-card>
</template>

<script setup>
import {computed, ref, onMounted} from "vue";
import {humanFileSize} from "../../utils/helpers";
import * as api from "../../services/api";
// https://www.npmjs.com/package/jsonpath
import jp from "jsonpath";
import {getStoreByName} from "../../utils/storeHelpers";
import {mandatory_props} from "../../utils/editorConfigFieldHelpers";
import {useCampaignStore} from "../../Stores/CampaignStore";

defineExpose({toggleFileSelect});

const max_upload_size = 500000; // 500 KB
const warnings = ref([]);
const is_uploading = ref(false);
const uploader = ref(null);
const upload_progress = ref(0);

const base_url = import.meta.env.VITE_DELIVERY_SYSTEM_URL;
const props = defineProps({
    ...mandatory_props,
    repeatableChild: {
        type: Boolean,
        default: false,
    }
});
const store = getStoreByName(props.storeName);
const campaignStore = useCampaignStore();
/**
 * dynamic json_path!
 * If this Component lives *within* a repeatable SubPanel, we need to find the current index
 * The json_path from the config will not be complete, hence we build it here
 */
const jsonPath = computed(() => {
    if (store.current_window_props && store.current_window_props.json_path) {

        let my_json_path = [];
        if (props.json_path) {
            my_json_path = jp.parse(props.json_path).map(e => e.expression.value);
        }

        const path = [
            ...jp.parse(store.current_window_props.json_path).map(e => e.expression.value),
            store.current_window_props.repeater_index,
            ...my_json_path
        ];

        try {
            return jp.stringify(path);
        } catch (e) {
            console.log("Could not stringify json_path", ...path);
            console.log(e);
        }
    }

    return props.json_path;
});
const value = computed({
    get() {
        return jp.value(store.content, jsonPath.value);
    },
    set(v) {
        jp.value(store.content, jsonPath.value, v);
    }
});

const imageUrl = computed(() => {
    if (value.value) {
        if (value.value.startsWith('http')) return value.value;
        return `${base_url}${value.value}`;
    }
    return null;
});

const emits = defineEmits(['deleted']);

// ----------------------------------------------------------------------------
// Show Assets
// ----------------------------------------------------------------------------

function handleImageClick() {
    // Click on placeholder image
    if (value.value === '') return toggleFileSelect();
    // click on "real" image
    handleShowCurrent();
}

/**
 * Show the current (customised) asset
 */
function handleShowCurrent() {
    const w = window.open(`${base_url}${value.value}`, '_blank');
    w.focus();
}


const hasOriginal = computed(() => {
    return props?.component_config?.has_original ?? true;
});

/**
 * Show the original asset
 */
function handleShowOriginal() {
    console.log(store);
    const url = window.route('assets.getOriginal', {
        step: store.uuid,
        json_path: jsonPath.value
    });

    api.client.get(url).then(response => {
        const w = window.open(`${base_url}${response.data.original_path}`, '_blank');
        w.focus();
    });
}

// ----------------------------------------------------------------------------
// File Upload
// ----------------------------------------------------------------------------
function toggleFileSelect() {
    uploader.value.click();
}
// Do not delete this function, it is needed for the drag and drop function!
function handleDragOver(event) { }
function handleFileDrop(event) {
    // Prevent default behavior (Prevent file from being opened)
    event.preventDefault();
    let source = event.dataTransfer.items || event.dataTransfer.files;

    // Use DataTransferItemList interface to access the file(s)
    for (let i = 0; i < source.length; i++) {
        // If dropped items aren't files, reject them
        if (source[i].kind === 'file') {
            let file = source[i].getAsFile();
            if (file.size < max_upload_size) {
                uploadFileForStep(file);
            } else {
                addWarning(file);
            }
        }
    }
}
function handleFileSelect(event) {
    for (let i = 0; i < event.target.files.length; i++) {
        let file = event.target.files[i];

        if (file.size < max_upload_size) {
            uploadFileForStep(file);
        } else {
            addWarning(file);
        }
    }
}
function uploadFileForStep(file) {
    const url = store.getAssetUploadUrl();
    const form = new FormData();
    form.append('asset', file);

    let config = {
        onUploadProgress: (progressEvent) => {
            upload_progress.value = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        }
    }

    is_uploading.value = true;
    api.client.post(url, form, config).then(response => {
        // Overwrite Image Path with new path
        value.value = response.data.path;

        // Determine which field we are dealing with
        const parsed = jp.parse(jsonPath.value);
        const field = parsed[2].expression.value;

        // Save the config
        switch (field) {
            // In case we use this ImageField inside the Landingpage Editor
            case 'meta': campaignStore.updateMeta();
                break;
            case 'components': campaignStore.updateComponents();
                break;

            // For normal apps/steps
            default: store.save();
                break;
        }
    }).catch(error => {
        console.error(error);
    }).finally(() => {
        is_uploading.value = false;
    });
}
function addWarning(file) {
    warnings.value.push(`Die Datei "${file.name}" ist mit ${humanFileSize(file.size, true)} für den Upload zu groß! Maximale Uploadgröße: ${humanFileSize(max_upload_size, true)}`);
}

// ----------------------------------------------------------------------------
// Delete / Restore
// ----------------------------------------------------------------------------

/**
 * Customised Assets are stored in the "/accounts/" folder
 *
 * @type {ComputedRef<*>}
 */
const isDeletable = computed(() => {
    return value.value === "" || value.value?.startsWith('/accounts');
});

function handleDelete() {
    if (props.storeName === "ComponentEditStore") {
        handleDeleteCustom();
    } else {
        handleDeleteCustomised();
    }
}

/**
 * handle delete customised (asset).
 * - Tell the server to delete the customised asset
 * - "We" (the client) then overwrite the current image with the original_path
 *   and then tell the server to save the current config from the store
 */
function handleDeleteCustomised() {
    // @Todo: Confirm delete!
    const url = store.getAssetDeleteUrl(jsonPath.value);

    // If the image is "empty" just remove it from the list
    if (value.value === "") {
        emits('deleted', jsonPath.value);
        return;
    }

    api.client.delete(url).then(response => {
        if (response.data.original_path) {
            // Overwrite Image Path with original path
            value.value = response.data.original_path;
            // Save the config
            store.save();
        } else {
            // There is no original, which means we are probably inside a repeater field.
            // Inform ImageRepeaterField via event (it will remove this ImageField from the list)
            emits('deleted', jsonPath.value);
        }
    });
}

/**
 * Handle Delete Custom
 * - There is not default!
 * - This is most likely used on the landingpage components
 */
function handleDeleteCustom() {
    // If the image is "empty" just remove it from the list
    if (value.value === "") {
        emits('deleted', jsonPath.value);
        return;
    }

    // Use the "real" asset storage path
    const url = store.getAssetDeleteUrl({path: value.value});

    // Determine which field we are dealing with
    const parsed = jp.parse(jsonPath.value);
    const field = parsed[2].expression.value;

    // Set the current value to empty
    value.value = "";

    // Figure out which field we are dealing with
    if (field === "components") {
        // Update the whole components json, and *THEN* delete the asset
        campaignStore.updateComponents().then(() => api.client.delete(url));
    }
    if (field === "meta") {
        // Update the whole meta json, and *THEN* delete the asset
        campaignStore.updateMeta().then(() => api.client.delete(url));
    }
}
</script>

<style scoped>

</style>
